.nav-items-container{
  margin-right: 20px;
}
.nav-items {
  padding-right:10px;
  font-size:13px;
  text-decoration: none;
  color: '#eee' !important;
}
.nav-items:hover {
  color: #0ac5bf;
}

.custom-container {
  width: 70px; /* Default for xs */
}

@media (min-width: 576px) {
  /* sm and up */
  .nav-items-container{
    margin-right: 20px;
  }
  .nav-items {
    padding-right:10px;
    font-size:13px;
    text-decoration: none;
    color: #eee;
  }
  .nav-items:hover {
    color: #0ac5bf;
  }
  .custom-container {
    width: 70px;
  }
}

@media (min-width: 768px) {
  /* md and up */
  .custom-container {
    width: 100px;
  }
  .nav-items-container{
    margin-right: 20px;
  }
  .nav-items {
    padding-right:10px;
    font-size:13px;
    text-decoration: none;
    color: #eee;
  }
  .nav-items:hover{
    color: #0ac5bf;
  }
}

@media (min-width: 1200px) {
  /* xl and up */
  .custom-container {
    width: 170px;
  }
  .nav-items-container{
    margin-right: 20px;
  }
  .nav-items {
    padding-right:10px;
    font-size:13px;
    text-decoration: none;
    color: #eee;
  }
  .nav-items:hover{
    color: #0ac5bf;
  }
}
.gradient-border-button {
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1;
}

.gradient-border-button .btn-content {
  position: relative;
  z-index: 2;
  display: inline-block;
  background: #fff; /* Background color of the button */
  border-radius: 4px;
  padding: 10px 20px;
}

.gradient-border-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 2px; /* Adjust the width of the border */
  background: linear-gradient(90deg, #07afe5, #0ac5bf, #06e9a4, #fed700);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
          mask-composite: exclude;
}

.gradient-border-button2 {
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1;
}

.gradient-border-button2 .btn-content {
  position: relative;
  z-index: 2;
  display: inline-block;
  background: #fff; /* Background color of the button */
  border-radius: 4px;
  padding: 10px 20px;
}

.gradient-border-button2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 2px; /* Adjust the width of the border */
  background: linear-gradient(90deg, #07afe5, #0ac5bf, #06e9a4, #fed700);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
          mask-composite: exclude;
}

.mosaic-background {
  position: relative;
  width: 100%;
  height: 180px; /* Adjust height as needed */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20px, 1fr)); /* Adjust size as needed */
  gap: 20px; /* Adjust gap as needed */
  background-color: lightgray; /* Background color for visibility */
}


.privacyBtn button, .privacyBtn button:hover, .privacyBtn button.active{
    background-color: transparent !important;
    border-color: transparent !important;
    color: #0AC5BF;
}

/* styles.css or a styled component */
.nav-link-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 0.7rem;
}

.nav-col-custom a{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.nav-container-custom {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
}

.profile-badge {
    display: block !important;
    color: white !important; /* Adjust text color for better contrast */
    background-color: green !important;
    position: absolute !important;
    top: 10px !important;
    right: 0 !important;
    width: 20px !important;
    height: 20px !important;
    z-index: 888 !important;
}
